import store from "@/store";

import * as turf from '@turf/turf';

const abadiLimitsValidator = () => {
    const validateAbadiLimitNGetInfo = (geomKML: any) => {
        const jurisdiction = store.getters.g_userdetails.jurisdiction;

        const g_distGeoFences = store.getters.g_distGeoFences;
        
        const distCurrentFence = g_distGeoFences.find((gf: any) => {
            const { district, geometry } = gf;
            return turf.booleanWithin(turf.polygon(geomKML.getCoordinates()), turf.multiPolygon(geometry.getCoordinates()))
        });

        if (distCurrentFence == undefined) return false;

        const { district, geometry } = distCurrentFence;
        // console.log(district, jurisdiction, geometry);

        if (jurisdiction != 'ALL' && district != jurisdiction) return false;
        
        let infoObj = {
            validAbadiLimit: false,
            district,
            taluk: ''
        };

        if(turf.booleanWithin(turf.polygon(geomKML.getCoordinates()), turf.multiPolygon(geometry.getCoordinates()))){            
            const g_distGeoFences = store.getters.g_talukGeoFences;

            const filteredTaluks = g_distGeoFences.filter((gft: any) => gft.district == district);
            // console.log(filteredTaluks);

            let talukIntersections = [];
            for (let i = 0; i < filteredTaluks.length; i++){
                let tgeom = filteredTaluks[i]['geometry'];
                let taluk = filteredTaluks[i]['taluk'];

                let intersectArea = turf.intersect(turf.polygon(geomKML.getCoordinates()), turf.multiPolygon(tgeom.getCoordinates()));
                // console.log(intersectArea);

                if(intersectArea){
                    let area = turf.area(intersectArea);
                    talukIntersections.push({
                        taluk,
                        area: area / Math.pow(10, 14)
                    });
                }
            }

            talukIntersections.sort((a, b) => b.area - a.area);

            infoObj = {
                ...infoObj,
                validAbadiLimit: true,
                taluk: talukIntersections[0]['taluk']
            }
        }

        return infoObj;
    }

    return {
        validateAbadiLimitNGetInfo
    }
}

export default abadiLimitsValidator;