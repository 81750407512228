import KML from 'ol/format/KML';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import drawFeaturesManager from '@/composables/kmls/drawFeaturesManager';
import abadiLimitsValidator from '@/composables/kmls/abadiLimitsValidator';

const kmlHanlder = () => {
    const { showGlobalToast } = globalToast();
    const { acceptAbadiLimit, clearAbadiKMLIfExists } = drawFeaturesManager();
    const { validateAbadiLimitNGetInfo } = abadiLimitsValidator();

    const handleFeature = (features: any) => {
        const map = store.getters.g_mapObj;

        let filteredFeatures = features.filter((feat: any) => {
            return feat.getGeometry()?.getType() == 'Polygon';
        });

        let feature = filteredFeatures[0];
        // console.log(feature);

        let cond1 = features.length != 0;
        let cond2 = filteredFeatures.length != 0;
        let cond3 = feature != undefined && feature != null;

        if(cond1 && cond2 && cond3){
            clearAbadiKMLIfExists();

            let lyr = new VectorLayer({
                source: new VectorSource({
                    features: [feature]
                }),
                zIndex: 3
            });

            lyr.set('name', 'drawnraddedkmlfeaturelyr');

            map.addLayer(lyr);

            let featureGeometry = feature.getGeometry();
            const { validAbadiLimit, district, taluk } = <any>validateAbadiLimitNGetInfo(featureGeometry);

            const jurisdiction = store.getters.g_userdetails.jurisdiction;
            if(validAbadiLimit){
                showGlobalToast(`Ok... Abadi Limits Inside ${jurisdiction} Jurisdiction`);
                acceptAbadiLimit(featureGeometry, district, taluk);
            } else {
                
                showGlobalToast(`Not Ok... Abadi Limits Outside Your ${jurisdiction} Jurisdiction`);
            }
        } else {
            showGlobalToast('KML File is not valid');
        }
    }

    const loadkml = (file: any) => {
        let reader = new FileReader();
        reader.onload = function () {

            let kmlstring = reader.result!;
            
            let kmlFeatures = new KML({
                extractStyles: false
            }).readFeatures(kmlstring, {
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:3857'
            });
    
            handleFeature(kmlFeatures);
        }
        reader.readAsText(file);
    }

    const loadKML = (e: any) => {
        let file = e.target.files[0];
        let fileFullname = file.name;
        let lastDot = fileFullname.lastIndexOf('.');
        let extension = fileFullname.substring(lastDot + 1);
        // console.log(fileFullname, extension);

        if (extension != 'kml'){
            showGlobalToast('Invalid File.. Only kml files are allowed...');
        } else {
            loadkml(file);
        }
    }

    return { loadKML }
}

export default kmlHanlder;