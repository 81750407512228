import store from '@/store';

import drawFeaturesManager from '@/composables/kmls/drawFeaturesManager';
import { defaultCurrentFeatureObj } from '@/store/storeAbadiUpload';

const zoomDiscardAbadiLimit = () => {
    const { clearAbadiKMLIfExists } = drawFeaturesManager();

    const zoomToAbadiLimit = () => {
        const map = store.getters.g_mapObj;
        const au_currentfeature = store.getters.au_currentfeature;
        
        try{
            map.getView().fit(au_currentfeature.geomObj.getExtent());
            map.getView().setZoom(map.getView().getZoom() - 2);
        } catch (e) {}
    }

    const discardAbadiLimit = () => {
        clearAbadiKMLIfExists();
        store.dispatch('au_currentfeature', defaultCurrentFeatureObj);
    }

    return { zoomToAbadiLimit, discardAbadiLimit }
}

export default zoomDiscardAbadiLimit;