import store from '@/store';

import Jimp from 'jimp';

import globalToast from '@/composables/common/globalToast';

const certificateHandler = () => {
    const { showGlobalToast } = globalToast();

    const loadImageFile = (file: any, extension: any) => {
        let reader = new FileReader();
        reader.onload = function () {
            let imgBuffer = <any>reader.result!;
            // console.log(imgBuffer);

            Jimp.read(imgBuffer).then(img => {
                let scaledImgForDisplay = img.resize(100, Jimp.AUTO);

                scaledImgForDisplay.getBase64Async(Jimp.MIME_JPEG).then(imgFordisplay => {
                    let imgtag = <any>document.getElementById("certimgtag");
                    imgtag.src = imgFordisplay;
                });

                let scaledImgForUpload = img.resize(300, Jimp.AUTO);

                scaledImgForUpload.getBase64Async(Jimp.MIME_JPEG).then(imgForUpload => {
                    // console.log(imgForUpload);

                    const au_currentfeature = store.getters.au_currentfeature;

                    let au_modcurrentfeature = {
                        ...au_currentfeature,
                        certBase64: imgForUpload,
                        certExt: extension,
                        validcertificate: true
                    };

                    // console.log(ku_modcurrentfeature);

                    store.dispatch('au_currentfeature', au_modcurrentfeature);
                });
            }).catch(err => console.log(err));
            
            // handleFeature(kmlFeatures, filename);
        }

        reader.readAsArrayBuffer(file);
    }

    const loadCertificate = (e: any) => {
        let file = e.target.files[0];
        let fileFullname = file.name;
        let lastDot = fileFullname.lastIndexOf('.');
        let extension = fileFullname.substring(lastDot + 1);
        // console.log(fileFullname, extension);

        let allowedExtensions = ['jpeg', 'jpg', 'png', 'bmp', 'tiff', 'gif'];
        if (allowedExtensions.includes(extension)){
            loadImageFile(file, extension);
        } else {
            showGlobalToast(`Invalid File.. Only ${allowedExtensions.join(', ')} files are allowed...`);
        }
    }

    return { loadCertificate }
}

export default certificateHandler;