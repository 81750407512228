import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import abadiLimitsLoader from '@/composables/geojsons/abadiLimitsLoader';

const abadiLimitsUploader = () => {
    const { showGlobalToast } = globalToast();
    const { loadAbadiLimits } = abadiLimitsLoader();

    const uploadAbadiLimit = () => {
        return new Promise((resolve, reject) => {
            const username = store.getters.g_userdetails.username;
            const password = store.getters.g_userdetails.password;

            const au_currentfeature = store.getters.au_currentfeature;
            // console.log(ku_currentfeature);

            const { attributes, certBase64, certExt, district, taluk, gjgeom } = au_currentfeature;

            store.dispatch('au_currentdistrict', district);

            let requestObj = {
                type: 'abadis',
                request: 'uploadabadi',
                validateusername: username,
                validatepassword: password,
                kmlobj: { attributes, certBase64, certExt, district, taluk, gjgeom }
            };

            console.log(requestObj);

            const g_wssDomain = store.getters.g_wssDomain;
            const g_portDir = store.getters.g_portDir;
            const PORT = g_portDir[requestObj['type']];

            const wssURL = `ws://${g_wssDomain}:${PORT}`;
            const ws = new WebSocket(wssURL);

            ws.addEventListener('error', () => {
                showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
            });
        
            ws.addEventListener('message', (event) => {
                let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
                console.log(responseObj);

                if(responseObj.requestStatus == 'success'){
                    showGlobalToast('Abadi Limit Uploaded');

                    resolve(0);
                } else if(responseObj.errorMsg == 'featureinplaceerror'){
                    showGlobalToast('Error: Abadi Limit Intersecting With Another in Records...');
                    loadAbadiLimits(store.getters.au_currentdistrict);

                    reject(1);
                } else {
                    showGlobalToast('Error Uploading Abadi Limit');
                    reject(1)
                }

                ws.close();
            });

            ws.addEventListener('open', (event) => {
                ws.send(btoa(JSON.stringify(requestObj)));
            });
        });
    }

    return { uploadAbadiLimit };
}

export default abadiLimitsUploader;