import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

import { Draw } from 'ol/interaction';
import GeometryType from 'ol/geom/GeometryType';
import GeoJSON from 'ol/format/GeoJSON';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import abadiLimitsValidator from '@/composables/kmls/abadiLimitsValidator';

const drawFeaturesManager = () => {
    const { showGlobalToast } = globalToast();
    const { validateAbadiLimitNGetInfo } = abadiLimitsValidator();

    const drawNewLayer = () => {
        const map = store.getters.g_mapObj;

        clearAbadiKMLIfExists();
        
        let source = new VectorSource({ wrapX: false });
        let vectorlyr = new VectorLayer({ source, zIndex: 3 });

        vectorlyr.set('name', 'drawnraddedkmlfeaturelyr');

        map.addLayer(vectorlyr);

        let draw = new Draw({
            source: source,
            type: GeometryType.POLYGON,
        });

        document.addEventListener('keyup', (e: any) => {
            if(e.key == 'Escape'){
                map.getInteractions().forEach(function (interaction: any) {
                    if(interaction instanceof Draw) {
                        map.removeInteraction(interaction);
                    }
                });
            }
        });

        document.addEventListener('keydown', (e: any) => {
            if (e.ctrlKey && e.key === 'z') {
                draw.removeLastPoint();
            }
        });

        draw.on('drawend', (event: any) => {
            map.getInteractions().pop();

            let featureGeometry = event.feature.getGeometry();
            const { validAbadiLimit, district, taluk } = <any>validateAbadiLimitNGetInfo(featureGeometry);

            const jurisdiction = store.getters.g_userdetails.jurisdiction;
            if(validAbadiLimit){
                showGlobalToast(`Ok... Abadi Limits Inside ${jurisdiction} Jurisdiction`);
                acceptAbadiLimit(featureGeometry, district, taluk);
            } else {
                
                showGlobalToast(`Not Ok... Abadi Limits Outside Your ${jurisdiction} Jurisdiction`);
            }
        });

        map.addInteraction(draw);
    }

    const acceptAbadiLimit = (featureGeometry: any, district: any, taluk: any) => {
        // console.log(district, taluk);
        const au_currentfeature = store.getters.au_currentfeature;

        let featGeomGJ = new GeoJSON().writeGeometry(featureGeometry, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });

        let au_modcurrentfeature = {
            ...au_currentfeature,
            geomObj: featureGeometry,
            gjgeom: featGeomGJ,
            validkml: true,
            district,
            taluk
        };

        // console.log(au_modcurrentfeature);

        store.dispatch('au_currentfeature', au_modcurrentfeature);
    }

    const clearAbadiKMLIfExists = () => {
        const map = store.getters.g_mapObj;

        try {
            map.getLayers().forEach((lyr: any) => {
                if (lyr.get('name') == 'drawnraddedkmlfeaturelyr') {
                    let source = lyr.getSource();
                    source.clear();

                    map.removeLayer(lyr);
                }
            });
        } catch (e) {}
    }

    return { drawNewLayer, acceptAbadiLimit, clearAbadiKMLIfExists }
}

export default drawFeaturesManager;