
import store from '@/store';
import { computed, defineComponent, onMounted, ref } from 'vue';
import './AbadisUpload.scss';

import globalToast from '@/composables/common/globalToast';
import drawFeaturesManager from '@/composables/kmls/drawFeaturesManager';
import kmlHanlder from '@/composables/kmls/kmlHandler';
import certificateHandler from '@/composables/kmls/certificateHandler';
import zoomdiscardAbadiLimit from '@/composables/kmls/zoomdiscardAbadiLimit';
import abadiLimitsUploader from '@/composables/kmls/abadiLimitsUploader';
import abadiLimitsLoader from '@/composables/geojsons/abadiLimitsLoader';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { drawNewLayer } = drawFeaturesManager();
        const { loadKML } = kmlHanlder();
        const { loadCertificate } = certificateHandler();
        const { zoomToAbadiLimit, discardAbadiLimit } = zoomdiscardAbadiLimit();
        const { uploadAbadiLimit } = abadiLimitsUploader();
        const { loadAbadiLimits } = abadiLimitsLoader();
        
        const au_currentfeature = computed(() => store.getters.au_currentfeature);

        const abadilimitname              = ref('');
        const noofproperties              = ref(0);
        // const startdate                   = ref();
        // const enddate                     = ref();
        const villagename                 = ref('');
        const lgdcode                     = ref('');
        const pocketscount                = ref(0);
        const grampanchayat               = ref('');
        // const chunnamarkingcompleted      = ref(false);

        const validAttributes = computed(() => {
            let conds = [];
            conds.push( abadilimitname.value                !=  ''  );
            conds.push( grampanchayat.value                 !=  ''  );
            conds.push( villagename.value                   !=  ''  );
            conds.push( lgdcode.value                       !=  ''  );
            conds.push( noofproperties.value                !=  0   );
            // conds.push( startdate.value                     !=  ''  );
            // conds.push( enddate.value                       !=  ''  );
            conds.push( pocketscount.value                  !=  0   );
            
            let allConds = conds.every(Boolean);
            if(allConds) sendAttributesToStore();
            
            return allConds;
        });

        const sendAttributesToStore = () => {
            const attributes = {
                'abadilimitname'            :   abadilimitname.value            ,
                'grampanchayat'             :   grampanchayat.value             ,
                'villagename'               :   villagename.value               , 
                'lgdcode'                   :   lgdcode.value                   ,   
                'noofproperties'            :   noofproperties.value            ,
                // 'startdate'                 :   startdate.value                 ,
                // 'enddate'                   :   enddate.value                   ,
                'pocketscount'              :   pocketscount.value              , 
            }

            const au_currentfeature = store.getters.au_currentfeature;

            let au_modcurrentfeature = {
                ...au_currentfeature,
                attributes
            };

            store.dispatch('au_currentfeature', au_modcurrentfeature);
        }
        
        const return1 = { 
            abadilimitname, noofproperties,
            // startdate, enddate,
            villagename, lgdcode,
            pocketscount, grampanchayat,
            // chunnamarkingcompleted
        };

        const fileEl = ref();
        // const certEl = ref();

        onMounted(() => {
            fileEl.value.addEventListener('change', loadKML);
            // certEl.value.addEventListener('change', loadCertificate);
        });

        const callDiscardAbadiLimit = () => {
            resetAttributes();
            discardAbadiLimit();
        }

        const resetAttributes = () => {
            abadilimitname.value                =  '';
            grampanchayat.value                 =  '';
            villagename.value                   =  '';
            lgdcode.value                       =  '';
            noofproperties.value                =  0 ;
            // startdate.value                     =  '';
            // enddate.value                       =  '';
            pocketscount.value                  =  0 ;

            // chunnamarkingcompleted.value        = false;

            fileEl.value.value = '';
            // certEl.value.value = '';

            // document.getElementById("certimgtag")?.removeAttribute('src');
        }

        const callUploadAbadiLimit = () => {
            // if(au_currentfeature.value.validkml == false || validAttributes.value == false || chunnamarkingcompleted.value == false || au_currentfeature.value.validcertificate == false){
            if(au_currentfeature.value.validkml == false || validAttributes.value == false){
                showGlobalToast('Kindly comply with the Checklist...');
                return;
            }

            uploadAbadiLimit().then(() => {
                callDiscardAbadiLimit();
                
                const au_currentdistrict = store.getters.au_currentdistrict;
                loadAbadiLimits(au_currentdistrict);
            }).catch(()=>{});
        }

        return {
            au_currentfeature, drawNewLayer, validAttributes,
            ...return1, fileEl,
            // certEl,
            zoomToAbadiLimit, callDiscardAbadiLimit, callUploadAbadiLimit
        }
    },
})
